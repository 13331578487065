<template>
  <ModuleForm
    :module-name="$t('moduleForm.likeDislike')"
    :module="poll"
    :show-header-info="disabled"
  >
    <template #header-buttons>
      <ModuleFormButton
        v-if="disabled"
        icon="edit"
        @click="goToEdit"
      />
      <ModuleFormButton
        v-else
        :title="$t('buttons.save')"
        @click="save"
      />
      <ModuleFormDeleteButton
        v-if="poll.id"
        :record-id="poll.id"
        store-name="poll"
        list-router-path="likeDislike"
      />
      <ModuleFormButton
        icon="close"
        @click="$router.push('/likeDislike')"
      />
    </template>
    <template #left-column>
      <Input
        v-model="poll.title"
        @blur="$v.poll.title.$touch()"
        :error="$v.poll.title.$error"
        id="poll_title"
        :label="$t('poll.title')"
        :placeholder="$t('poll.title_placeholder')"
        :disabled="disabled"
      />
      <Select
        v-model="poll.category"
        :options="categories"
        id="poll_categories"
        :label="$t('poll.category')"
        no-empty-value
        :disabled="disabled"
      />
      <Input
        v-model="poll.city"
        id="poll_city"
        :label="$t('poll.city')"
        :disabled="disabled"
      />
      <Textarea
        v-model="poll.description"
        id="poll_description"
        :label="$t('poll.description')"
        :rows=4
        :disabled="disabled"
      />
      <div class="select-types">
        <Select
          v-if="displayTypes"
          class="select-types__select"
          v-model="poll.displayType"
          :options="displayTypes"
          id="poll_displayType"
          :label="$t('poll.display_type')"
          no-empty-value
          :disabled="disabled"
        />
        <Select
          v-if="restrictionTypes"
          class="select-types__select"
          v-model="poll.restrictionType"
          :options="restrictionTypes"
          id="poll_restrictionType"
          :label="$t('poll.restriction_type')"
          no-empty-value
          :disabled="disabled"
        />
      </div>
      <div class="published-dates">
        <Datepicker
          class="published-dates__datepicker"
          v-model="poll.endOfVoting"
          id="poll_endOfVoting"
          :label="$t('poll.end_of_voting')"
          :disabled="disabled"
        />
        <Datepicker
          class="published-dates__datepicker"
          v-model="poll.orderDate"
          id="poll_orderDate"
          :label="$t('poll.order_date')"
          :show-set-now="!disabled"
          :disabled="disabled"
        />
      </div>
      <!-- hide till CMS-541 is resolved -->
      <Datepicker
        v-if="false"
        class="published-dates__datepicker"
        v-model="poll.publishedUntil"
        id="poll_publishedUntil"
        :label="$t('poll.published_until')"
        :disabled="disabled"
      />
    </template>
    <template #right-column>
      <div v-if="disabled">
        <div class="title-text">
          {{ $t('poll.results') }}
        </div>
        <PollResults :poll="poll" />
      </div>
      <div class="title-text">
        {{ $t('poll.image') }}
      </div>
      <ModuleFormPhotoBox
        :image="poll.imageInfo"
        :disabled="disabled"
        @set-media="setImage"
        @remove-media="removeImage"
      />
      <OutlinedCheckbox
        v-model="poll.enabledVoting"
        id="poll_enabledVoting"
        :label="$t('poll.enabled_voting')"
        :disabled="disabled"
      />
      <OutlinedCheckbox
        v-model="poll.hidden"
        id="poll_hidden"
        :label="$t('poll.hidden')"
        :disabled="disabled"
      />
      <!-- hide till CMS-541 is resolved -->
      <OutlinedCheckbox
        v-if="false"
        v-model="poll.postpone"
        id="poll_postpone"
        :label="$t('poll.postpone')"
        :disabled="disabled"
      />
      <!-- hide till CMS-541 is resolved -->
      <OutlinedCheckbox
        v-if="false"
        v-model="poll.unpublished"
        id="poll_setUnpublished"
        :label="$t('media.set_unpublished')"
        :disabled="disabled"
      />
    </template>
  </ModuleForm>
</template>

<script>
import PollModel from '@/model/PollModel'
import Input from '@/components/form/inputs/Input'
import Select from '@/components/form/select/Select'
import Datepicker from '@/components/form/Datepicker'
import { POLL_TYPE_LIKE_DISLIKE } from '@/components/mixins/valueObject/PollTypeMixin'
import { AVAILABLE_VALUES as AVAILABLE_DISPLAY_TYPES } from '@/model/ValueObject/PollDisplayType'
import { AVAILABLE_VALUES as AVAILABLE_RESTRICTION_TYPES } from '@/model/ValueObject/PollRestrictionType'
import { maxLength, minLength, required } from 'vuelidate/lib/validators'
import NotifyService from '@/services/NotifyService'
import CoreApi from '@/api/core'
import Textarea from '@/components/form/Textarea'
import ModuleFormPhotoBox from '@/components/shared/ModuleFormPhotoBox'
import ModuleFormDeleteButton from '@/components/shared/ModuleFormDeleteButton'
import ModuleFormButton from '@/components/shared/ModuleFormButton'
import ModuleForm from '@/components/shared/ModuleForm'
import OutlinedCheckbox from '@/components/form/OutlinedCheckbox'
import PollResults from '@/components/poll/PollResults'

export default {
  name: 'LikeDislikeNewView',
  data () {
    return {
      poll: this._.cloneDeep(PollModel),
      categories: []
    }
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    displayTypes () {
      return AVAILABLE_DISPLAY_TYPES
    },
    restrictionTypes () {
      return AVAILABLE_RESTRICTION_TYPES
    }
  },
  validations: {
    poll: {
      title: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(250)
      }
    }
  },
  components: {
    PollResults,
    Input,
    Select,
    ModuleFormPhotoBox,
    ModuleFormDeleteButton,
    ModuleFormButton,
    ModuleForm,
    OutlinedCheckbox,
    Datepicker,
    Textarea
  },
  methods: {
    preparePollRequest (poll) {
      if (poll.publishedUntil === '') {
        delete poll.publishedUntil
      }
      return poll
    },
    async save () {
      this.addOption(this.$t('poll.option_like'))
      this.addOption(this.$t('poll.option_dislike'))

      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
        return
      }
      this.$store.dispatch('poll/create', this.preparePollRequest(this.poll))
        .then(() => {
          this.poll = this.$store.getters['poll/detail']
          if (this.$store.getters['poll/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_created'))
            this.goToEdit()
          } else {
            NotifyService.setErrorMessage(this.$store.getters['poll/error'])
          }
        })
        .catch(error => console.log(error))
    },
    setImage (medias) {
      medias.forEach(media => {
        this.poll.imageInfo = media
        this.poll.image = media.id
      })
    },
    removeImage () {
      this.poll.imageInfo = {}
      this.poll.image = null
    },
    addOption (title = '') {
      const result = {
        title: title,
        image: null
      }
      this.poll.options.push(result)
    },
    getPollCategories () {
      CoreApi().get('/pollCategory')
        .then(response => {
          Object.keys(response.data).forEach(id => {
            this.categories.push({ id: id, title: response.data[id] })
          })
        })
        .catch(error => {
          console.log(error)
        })
    },
    goToEdit () {
      this.$router.push('/likeDislike/' + this.poll.id + '/edit')
    }
  },
  created () {
    this.getPollCategories()
    this.poll.type = POLL_TYPE_LIKE_DISLIKE
  }
}
</script>
<style scoped lang="scss">
.select-types {
  display: flex;
  gap: 2%;
  &__select {
    flex: 0 0 49%;
  }
}
.published-dates {
  display: flex;
  gap: 2%;
  @include bp(0 7) {
    flex-wrap: wrap;
  }
  &__datepicker {
    @include bp(0 7) {
      flex: 0 0 100%;
    }
    @include bp(11) {
      flex: 0 0 49%;
    }
  }
}
.title-text {
  color: #8A96AC;
  font-size: rem(14px);
}
</style>
